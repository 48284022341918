import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngxs/store";
import {
    ClearTimeEntryTypes,
    SetTimeEntryTypes,
} from "src/store/time-entries-types/actions/time-entry-types.action";
import { LoadTimeEntryTypesPagination } from "src/store/time-entries-types/actions/pagination.action";
import {
    GetFormTimeEntries,
    GetTimeEntryTypeByIdResponseModel,
    TimeEntriesResponseModel,
    TimeEntryCreateTemplate,
    TimeEntryTypes,
} from "src/app/services/response-models/time-entries.response.model";
import { FormTimeEntry } from "src/app/services/response-models/form.response.model";
import {
    GeneralSuccessResp,
    SuccessAdd,
} from "src/app/services/response-models/companies.response.model";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class TimeEntryService {
    private readonly timeEntryTypesUrl: string = `${environment.apiBaseUrl}time_entry_types`;
    private readonly timeEntriesUrl: string = `${environment.apiBaseUrl}time_entries`;
    constructor(
        private http: HttpClient,
        private store: Store
    ) {}

    public async getTimeEntryById(id: string): Promise<TimeEntryTypes> {
        const url = `${this.timeEntryTypesUrl}/${id}`;
        const { data } = await lastValueFrom(this.http.get<GetTimeEntryTypeByIdResponseModel>(url));
        return data;
    }

    public async getTimeEntryTypes(
        page: number | string = "1",
        searchText: string = "",
        sort: string = "name",
        direction: string = "asc",
        limit = "20"
    ): Promise<TimeEntryTypes[]> {
        const headers = {
            params: {
                include: "time_entry_value_types",
                page: page.toString(),
                name: searchText,
                sort,
                direction,
                limit,
            },
        };

        const { data, pagination } = await lastValueFrom(
            this.http.get<TimeEntriesResponseModel>(this.timeEntryTypesUrl, headers)
        );

        this.store.dispatch(new ClearTimeEntryTypes());
        this.store.dispatch(new SetTimeEntryTypes(data));
        this.store.dispatch(new LoadTimeEntryTypesPagination(pagination));

        return data;
    }

    public async getTimeEntriesByFormId(form_id: string): Promise<FormTimeEntry[]> {
        const headers = {
            params: {
                form_id,
                include: "time_entry_types",
            },
        };

        const { data } = await lastValueFrom(
            this.http.get<GetFormTimeEntries>(this.timeEntriesUrl, headers)
        );

        return data;
    }

    public createTimeEntry(timeEntry: TimeEntryCreateTemplate): Promise<SuccessAdd> {
        return lastValueFrom(this.http.post<SuccessAdd>(this.timeEntriesUrl, timeEntry));
    }

    public editTimeEntry(timeEntry: FormTimeEntry): Promise<GeneralSuccessResp> {
        const url = `${this.timeEntriesUrl}/${timeEntry.id}`;

        delete timeEntry.user;
        delete timeEntry.project;
        delete timeEntry.time_entry_type;
        timeEntry.is_all_day = true;

        return lastValueFrom(this.http.put<GeneralSuccessResp>(url, timeEntry));
    }

    public deleteTimeEntry(timeEntryId: string): Promise<GeneralSuccessResp> {
        const url = `${this.timeEntriesUrl}/${timeEntryId}`;

        return lastValueFrom(this.http.delete<GeneralSuccessResp>(url));
    }
}
